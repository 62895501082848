import "../css/hero.css";
import heroImg from "../assets/philia.png";
import { Link } from "react-router-dom";
import r01 from "../assets/revs/01.jpg";
import r02 from "../assets/revs/02.jpg";
import r03 from "../assets/revs/03.jpg";
import r04 from "../assets/revs/04.jpg";
import { useState } from "react";
import { useEffect } from "react";
import bgImg from "../assets/header.png";

const RenderStar = () => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M10.6673 8.41662C10.4946 8.58396 10.4153 8.82596 10.4546 9.06329L11.0473 12.3433C11.0973 12.6213 10.9799 12.9026 10.7473 13.0633C10.5193 13.23 10.2159 13.25 9.96727 13.1166L7.0146 11.5766C6.91194 11.522 6.79794 11.4926 6.68127 11.4893L6.5006 11.4893C6.43794 11.4986 6.3766 11.5186 6.3206 11.5493L3.36727 13.0966C3.22127 13.17 3.05594 13.196 2.89394 13.17C2.49927 13.0953 2.23594 12.7193 2.3006 12.3226L2.89394 9.04262C2.93327 8.80329 2.85394 8.55996 2.68127 8.38996L0.273938 6.05662C0.0726045 5.86129 0.00260455 5.56796 0.0946045 5.30329C0.183938 5.03929 0.411938 4.84662 0.687271 4.80329L4.0006 4.32262C4.2526 4.29662 4.47394 4.14329 4.58727 3.91662L6.04727 0.923288C6.08194 0.856621 6.1266 0.795288 6.1806 0.743288L6.2406 0.696621C6.27194 0.661955 6.30794 0.633288 6.34794 0.609955L6.4206 0.583288L6.53394 0.536621L6.8146 0.536621C7.06527 0.562621 7.28594 0.712621 7.40127 0.936621L8.8806 3.91662C8.98727 4.13462 9.1946 4.28596 9.43394 4.32262L12.7473 4.80329C13.0273 4.84329 13.2613 5.03662 13.3539 5.30329C13.4413 5.57062 13.3659 5.86396 13.1606 6.05662L10.6673 8.41662Z'
			fill='#FF876C'
		/>
	</svg>
);

const RenderBtns = ({
	className = "",
	t1,
	to1 = "/#services",
	to2 = "https://www.youtube.com/channel/UC9UPvE_C1y41FdwF83xP2TA",
	el2,
}) => {
	return (
		<div className={`flex ubuntu hbtns align-center ${className}`}>
			{to1.includes("https") ? (
				<a href={to1} target='_blank' rel='noopener noreferrer'>
					<div>{t1}</div>
				</a>
			) : (
				<Link to={to1}>
					<div>{t1}</div>
				</Link>
			)}

			<div
				onClick={() => {
					if (el2) {
						document.getElementById(el2).scrollIntoView({ behavior: "smooth" });
					} else {
						window.open(to2);
					}
				}}
				className='flex align-center'
			>
				<svg
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10 3.21193e-07C4.48 1.43895e-07 -1.43895e-07 4.48 -3.21193e-07 10C-4.98492e-07 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 4.98492e-07 10 3.21193e-07ZM12.66 11.73L11.38 12.47L10.1 13.21C8.45 14.16 7.1 13.38 7.1 11.48L7.1 10L7.1 8.52C7.1 6.61 8.45 5.84 10.1 6.79L11.38 7.53L12.66 8.27C14.31 9.22 14.31 10.78 12.66 11.73Z'
						fill='#282C4B'
					/>
				</svg>
				Watch Video
			</div>
		</div>
	);
};

function Hero({
	btnP = "Get Started",
	t1 = "Make",
	t2 = "Multifold",
	t3 = "Returns ",
	sub = '"csPILLAI™ is a BROAD VENTURE BUILDER that offers a STARTUP FACTORY powered by blockchain and AI to facilitate the creation and sale of startups through credit-based crowdfunding, seed funding with convertible notes, franchised production and management, and cryptocurrency-based services, as well as a PLATFORM for investors in startups, scaleups, entrepreneurship, and mentoring."',
	sb = true,
	to2,
	to1 = "/#services",
	el2,
}) {
	const [index, setIndex] = useState(0);

	const t4 = ["Remarkably!", "Dependably!", "Effortlessly!"];

	useEffect(() => {
		setTimeout(() => {
			setIndex((prev) => {
				const num = prev + 1;
				if (num > 2) return 0;
				return num;
			});
		}, [3000]);
	}, [index]);

	return (
		<>
			<div id='home' style={{ height: 60 }}></div>
			<div className='container flex mfc align-center bg hero wrapper'>
				<div className='txts'>
					{sb && (
						<div className='tx flex align-center'>
							<div className='svg'>
								<svg
									width='30'
									height='30'
									viewBox='0 0 30 30'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										opacity='0.4'
										d='M13.4376 3.0624C14.3001 2.3249 15.7126 2.3249 16.5876 3.0624L18.5626 4.7624C18.9376 5.0874 19.6376 5.3499 20.1376 5.3499L22.2626 5.3499C23.5876 5.3499 24.6751 6.4374 24.6751 7.7624L24.6751 9.8874C24.6751 10.3749 24.9376 11.0874 25.2626 11.4624L26.9626 13.4374C27.7001 14.2999 27.7001 15.7124 26.9626 16.5874L25.2626 18.5624C24.9376 18.9374 24.6751 19.6374 24.6751 20.1374L24.6751 22.2624C24.6751 23.5874 23.5876 24.6749 22.2626 24.6749L20.1376 24.6749C19.6501 24.6749 18.9376 24.9374 18.5626 25.2624L16.5876 26.9624C15.7251 27.6999 14.3126 27.6999 13.4376 26.9624L11.4626 25.2624C11.0876 24.9374 10.3876 24.6749 9.8876 24.6749L7.7251 24.6749C6.4001 24.6749 5.3126 23.5874 5.3126 22.2624L5.3126 20.1249C5.3126 19.6374 5.0501 18.9374 4.7376 18.5624L3.0501 16.5749C2.3251 15.7124 2.3251 14.3124 3.0501 13.4499L4.7376 11.4624C5.0501 11.0874 5.3126 10.3874 5.3126 9.8999L5.3126 7.7499C5.3126 6.4249 6.4001 5.3374 7.7251 5.3374L9.8876 5.3374C10.3751 5.3374 11.0876 5.0749 11.4626 4.7499L13.4376 3.0624Z'
										fill='#F07922'
									/>
									<path
										d='M13.4877 18.9626C13.2377 18.9626 13.0002 18.8626 12.8252 18.6876L9.8002 15.6626C9.4377 15.3001 9.4377 14.7001 9.8002 14.3376C10.1627 13.9751 10.7627 13.9751 11.1252 14.3376L13.4877 16.7001L18.8627 11.3251C19.2252 10.9626 19.8252 10.9626 20.1877 11.3251C20.5502 11.6876 20.5502 12.2876 20.1877 12.6501L14.1502 18.6876C13.9752 18.8626 13.7377 18.9626 13.4877 18.9626Z'
										fill='#F07922'
									/>
								</svg>
							</div>
							<span className='ubuntu'>
								Alternative Investment Facilitators
							</span>
						</div>
					)}
					<h2 className='htitle ubuntu'>
						{t1}{" "}
						<span>
							{t2}{" "}
							<svg
								width='254'
								height='11'
								viewBox='0 0 254 11'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1 9.74479C99.4122 -1.61197 154.588 -2.21399 253 9.74479'
									stroke='#FF698D'
									strokeWidth='2'
								/>
							</svg>
						</span>
						<br />
						{t3}
						<br />
						<span className='t4'>{t4[index]}</span>
					</h2>
					<br />
					<p className='manrope sub'>
						csPILLAI®'s AI-powered fintech platform unlocks alternative
						investments with precise insights and smart recommendations,
						highlighting prime investment opportunities.
						<br />
						{sb && (
							<a
								href='https://www.linkedin.com/in/sureshbabupillai/'
								target='__blank'
								className='spp manrope'
							>
								_SureshPillai
							</a>
						)}
					</p>

					<RenderBtns
						el2={el2}
						to1={to1}
						to2={to2}
						t1={btnP}
						className='desktop'
					/>
				</div>
				<div className='img'>
					<img
						src={heroImg}
						alt='csPILLAI ™ Business & Startup Investing Online Platform!'
					/>
					<div className='reviews ubuntu bg'>
						<div style={{ alignItems: "baseline" }} className='flex'>
							<h2>5.0</h2>
							{[1, 2, 3, 4, 5].map((p, idx) => (
								<RenderStar key={idx} />
							))}
						</div>
						<div className='flex'>
							{[r01, r02, r03, r04].map((p, idx) => (
								<div key={idx} className='img_rev'>
									<img src={p} alt='' />
								</div>
							))}
						</div>
					</div>
				</div>
				{sb && (
					<div className='container mobile container bg manrope jm flex justify-center align-center'>
						<p style={{ textAlign: "center" }}>
							"YOU INVEST; WE BUILD :: WE STRATEGIZE; YOU GAIN"
						</p>
					</div>
				)}
				<RenderBtns
					el2={el2}
					to1={to1}
					to2={to2}
					t1={btnP}
					className='mobile'
				/>
			</div>
			{sb && (
				<div
					style={{ padding: "2%", textAlign: "center" }}
					className='container desktop container bg manrope jm flex justify-center align-center'
				>
					<p>"YOU INVEST; WE BUILD :: WE STRATEGIZE; YOU GAIN"</p>
				</div>
			)}
		</>
	);
}

export default Hero;
